.site-layout-content {
  min-height: 280px;
}

body {
	background-color: rgb(240, 242, 245);
	margin: 0;
}

.route-wrapper {
	position: relative;
	background: #fff;
}

.route-wrapper > div {
  position: absolute;
	width: 100%;
	padding: 24px;
	background: #fff;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.7);
	}

	70% {
		box-shadow: 0 0 0 12px rgba(231, 76, 60, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
	}
}

@import url('https://rsms.me/inter/inter.css');
body {
	font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  body { font-family: 'Inter var', sans-serif; }
}
